export default {
  name: "LandingPage",
  created() {
    this.$nextTick(function () {
      this.getFocusDate();
    });

  },
  mounted() { },
  data() {
    return {
      windowWB: null,
      limit: 10,
      limitText: 'Show All'
    };
  },
  methods: {
    ShowAll() {
      if (this.limitText === 'Show All') {
        this.limit = ''
        this.limitText = 'Show Latest'
      } else if (this.limitText === 'Show Latest') {
        this.limit = 10
        this.limitText = 'Show All'
      }
      this.getFocusDate()
    },
    getFocusDate: function () {
      let _this = this;
      $.ajax({
        type: "post",
        url: this.$js.webservice + "GetDeployedNewsletter",
        data: { token: "webkey", limit: this.limit },
        dataType: "json",
        success: function (data) {
          if (data.status === "SUCCESS") {
            _this.assignmentFocus(data.text);
          }
        },
        error: function (error) {
          console.log(error);
        }
      });
    },
    assignmentFocus: function (data) {
      $("#focus_content_ios .focus_time")
        .find("ul")
        .empty();
      $("#focus_content_pc .focus_time")
        .find("ul")
        .empty();
      $(".focus_information")
        .find("ul")
        .empty();
      let _this = this;
      var timeLi_pc = "";
      var timeLi_ios = "";
      var informationLi_pc = "";
      for (var i = 0, max = data.length; i < max; i++) {
        timeLi_pc += "<li>";
        informationLi_pc += "<li>";
        timeLi_pc +=
          '<span class="focus_date">' +
          this.gTiem(data[i].publish_date.split(" ")[0])[0] +
          "&nbsp;" +
          this.gTiem(data[i].publish_date.split(" ")[0])[1] +
          "&nbsp;" +
          this.gTiem(data[i].publish_date.split(" ")[0])[2] +
          "</span>";
        // for (var n = 0, nMax = data[i].overview.split(/\n/g).length; n < nMax; n++) {

        // }
        timeLi_pc += '<div class="focus_title">' + data[i].title + "</div>";
        timeLi_pc +=
          '<span class="newsletterid" style="display: none;">' +
          data[i].newsletterid +
          "<span>";
        timeLi_pc += "</li>";
        informationLi_pc += '<img width="100%">';
        // informationLi += '<iframe frameborder="0" width="100%"></iframe>';
        // informationLi += '<div class="getH"></div>'
        informationLi_pc += "</li>";

        // ios
        timeLi_ios += "<li>";
        timeLi_ios += '<div class="focus_t">'
        timeLi_ios +=
          '<span class="focus_date">' +
          this.gTiem(data[i].publish_date.split(" ")[0])[0] +
          "&nbsp;" +
          this.gTiem(data[i].publish_date.split(" ")[0])[1] +
          "&nbsp;" +
          this.gTiem(data[i].publish_date.split(" ")[0])[2] +
          "</span>";
        // for (var n = 0, nMax = data[i].overview.split(/\n/g).length; n < nMax; n++) {

        // }
        timeLi_ios += '<div class="focus_title">' + data[i].title + "</div>";
        timeLi_ios +=
          '<span class="newsletterid" style="display: none;">' +
          data[i].newsletterid +
          "<span>";
        timeLi_ios += '</div>';
        timeLi_ios += "<div class='focus_c'>"
        timeLi_ios += '<img width="100%">';
        timeLi_ios += "</div>";
        timeLi_ios += "</li>";
      }
      $("#focus_content_ios .focus_time")
        .find("ul")
        .append(timeLi_ios);
      $("#focus_content_pc .focus_time")
        .find("ul")
        .append(timeLi_pc);
      $(".focus_information")
        .find("ul")
        .append(informationLi_pc);
      let windowWidth = $(window).width();
      $(window).resize(function () {
        let _t = _this
        windowWidth = $(window).width();
        if (windowWidth < 768 || windowWidth === 768) {
          $('#focus_content_pc').hide()
          $('#focus_content_ios').show()
          if (!_this.getUrlParam("newsletterid")) {
            $("#focus_content_ios .focus_time ul li")
              .eq(0).find('.focus_t')
              .addClass("choice_li");
            $("#focus_content_ios .focus_time ul li")
              .eq(0).find('.focus_c')
              .slideDown("slow");
            _this.getWifData(
              data[0].newsletterid,
              $("#focus_content_ios .focus_time ul li").eq(0).find('.focus_c')
            );
          } else {
            $.each(data, function (index, value) {
              if (_t.getUrlParam("newsletterid") === value.newsletterid) {
                $("#focus_content_ios .focus_time ul li")
                  .eq(index).find('.focus_t')
                  .addClass("choice_li");
                $("#focus_content_ios .focus_time ul li")
                  .eq(index).find('.focus_c')
                  .slideDown("slow");
                _t.getWifData(
                  data[index].newsletterid,
                  $("#focus_content_ios .focus_time ul li").eq(index).find('.focus_c')
                );
              }
            });
          }
          this.focusResultIos()
        } else {
          $('#focus_content_pc').show()
          $('#focus_content_ios').hide()
          if (!_this.getUrlParam("newsletterid")) {
            $("#focus_content_pc .focus_time ul li")
              .eq(0)
              .addClass("choice_li");
            $("#focus_content_pc .focus_information ul li")
              .eq(0)
              .show();
            _this.getWifData(
              data[0].newsletterid,
              $("#focus_content_pc .focus_information ul li").eq(0)
            );
          } else {
            $.each(data, function (index, value) {
              if (_t.getUrlParam("newsletterid") === value.newsletterid) {
                $("#focus_content_pc .focus_time ul li")
                  .eq(index)
                  .addClass("choice_li");
                $("#focus_content_pc .focus_information ul li")
                  .eq(index)
                  .show();
                _t.getWifData(
                  data[index].newsletterid,
                  $("#focus_content_pc .focus_information ul li").eq(index)
                );
              }
            });
          }
          this.focusResult();
        }
      })
      if (windowWidth < 768 || windowWidth === 768) {
        $('#focus_content_pc').hide()
        $('#focus_content_ios').show()
        if (!this.getUrlParam("newsletterid")) {
          $("#focus_content_ios .focus_time ul li")
            .eq(0).find('.focus_t')
            .addClass("choice_li");
          $("#focus_content_ios .focus_time ul li")
            .eq(0).find('.focus_c')
            .slideDown("slow");
          this.getWifData(
            data[0].newsletterid,
            $("#focus_content_ios .focus_time ul li").eq(0).find('.focus_c')
          );
        } else {
          $.each(data, function (index, value) {
            if (_this.getUrlParam("newsletterid") === value.newsletterid) {
              $("#focus_content_ios .focus_time ul li")
                .eq(index).find('.focus_t')
                .addClass("choice_li");
              $("#focus_content_ios .focus_time ul li")
                .eq(index).find('.focus_c')
                .slideDown("slow");
              _this.getWifData(
                data[index].newsletterid,
                $("#focus_content_ios .focus_time ul li").eq(index).find('.focus_c')
              );
            }
          });
        }
        this.focusResultIos()
      } else {
        $('#focus_content_pc').show()
        $('#focus_content_ios').hide()
        if (!this.getUrlParam("newsletterid")) {
          $("#focus_content_pc .focus_time ul li")
            .eq(0)
            .addClass("choice_li");
          $("#focus_content_pc .focus_information ul li")
            .eq(0)
            .show();
          this.getWifData(
            data[0].newsletterid,
            $("#focus_content_pc .focus_information ul li").eq(0)
          );
        } else {
          $.each(data, function (index, value) {
            if (_this.getUrlParam("newsletterid") === value.newsletterid) {
              $("#focus_content_pc .focus_time ul li")
                .eq(index)
                .addClass("choice_li");
              $("#focus_content_pc .focus_information ul li")
                .eq(index)
                .show();
              _this.getWifData(
                data[index].newsletterid,
                $("#focus_content_pc .focus_information ul li").eq(index)
              );
            }
          });
        }
        this.focusResult();
      }
    },
    // 去除html
    stripHtml: function (html) {
      var tmp = document.createElement("DIV");
      tmp.innerHTML = html;
      return tmp.textContent || tmp.innerText || "";
    },
    //获取url中的参数
    getUrlParam: function (name) {
      console.log(1);
      var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)"); //构造一个含有目标参数的正则表达式对象
      var r = window.location.search.substr(1).match(reg); //匹配目标参数
      if (r != null) return unescape(r[2]);
      return null; //返回参数值
    },
    focusResult: function () {
      let _this = this;
      $(".focus_time ul li").click(function () {
        var index = $(".focus_time ul li").index($(this));
        // _this.$js.SetAnchor($('.focus_information').find('ul').offset().top)
        $(this).addClass("choice_li");
        $(this)
          .siblings("li")
          .removeClass("choice_li");
        $(".focus_information ul li")
          .eq(index)
          .show();
        $(".focus_information ul li")
          .eq(index)
          .siblings("li")
          .hide();
        $(this).removeClass("suspension_li");
        if (
          !$(".focus_information ul li")
            .eq(index)
            .find("img")
            .attr("src")
        ) {
          _this.getWifData(
            $(this)
              .find(".newsletterid")
              .text(),
            $(".focus_information ul li").eq(index)
          );
        }
      });
    },
    focusResultIos: function () {
      let _this = this
      let slideT = ''
      $("#focus_content_ios ul li").click(function () {
        let then = this
        let index_ios = $("#focus_content_ios .focus_time ul li").index($(this));
        $(this).find('.focus_c').slideToggle(function () {
          slideT = $(then).find('.focus_c').is(":visible")
          if (slideT) {
            $(then).find('.focus_t').addClass('choice_li')
            $(then).siblings('li').find('.focus_t').removeClass('choice_li')
            $(then).siblings('li').find('.focus_c').slideUp()
          } else {
            $(then).find('.focus_t').removeClass('choice_li')
          }
        });
        if (
          !$(this)
            .find(".focus_c img")
            .attr("src")
        ) {
          _this.getWifData(
            $(this)
              .find(".focus_t .newsletterid")
              .text(),
            $(this).find('.focus_c')
          );
        }
      })
    },
    gTiem: function (ds) {
      //TODO: remove this
      var nds = [];
      var dds = ds.split("-");
      var MMM = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec"
      ];
      if (dds.length === 3) {
        var dd = Number(dds[2]);
        var mm = MMM[Number(dds[1]) - 1];
        var yyyy = dds[0].substring(4 - 2, 4);
        nds[0] = dd;
        nds[1] = mm;
        nds[2] = yyyy;
      }
      return nds;
    },
    // 获取高度
    getHeight: function (body, div) {
      body.find('.getH').append(div)
      var HH = body.find(".newsletter").height()
      // console.log(body.find(".newsletter").height());
      body.find('.getH').empty()
      return HH + 100
    },
    getWifData: function (id, body) {
      console.log(id);
      console.log(body);
      var _this = this
      $.ajax({
        type: "post",
        url: this.$js.webservice + "GetDeployedNewsletterActicle",
        data: { newsletterid: id },
        dataType: "json",
        success: function (data) {
          if (data.status === "SUCCESS") {
            // console.log(data);
            body.find("img").attr("src", data.text[0].picturization);
            // body.find("iframe").attr({
            //   "srcdoc": data.text[0].edm,
            //   "height": _this.getHeight(body, data.text[0].edm)
            // });
          }
        },
        error: function (error) {
          console.log(error);
        }
      });
    }
  }
};

